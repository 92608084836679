<!-- @Author: Yu_Bo -->
<template>
	<div class='tiben_market'>
    <div class="market_nav">
      <div class="nav_top">
        <div class="nav_left">
          <div class="text" :class="navIndex==index?'text_active':''" v-for="(item,index) in navList" :key="index" @click="navBtn(index)">{{item.name}}</div>
        </div>
        <div class="nav_right">
          <div class="right_select" v-if="false">
            <el-select v-model="editValue" size='small' placeholder="请选择" :clearable='true'>
              <el-option v-for="item in editOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="right_select">
            <el-select v-model="tiValue" size='small' placeholder="请选择" :clearable='true' @change="bookListBtn">
              <el-option v-for="item in tiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="t_input">
            <el-input v-model="inputTxt" @keyup.enter.native="bookListBtn" @clear='bookListBtn' :clearable="true" size='small' prefix-icon='el-icon-search' placeholder="搜索台本名称"></el-input>
          </div>
        </div>
      </div>
      <div class="next_list" v-if="nextList.length">
        <div class="next_nav" :class="nextIndex===index?'next_active':''" v-for="(item,index) in nextList" :key="index" @click="nextBtn(index)">{{item.name}}</div>
      </div>
    </div>
    <!--  -->
    <div class="book_info">
      <div class="book_info_main">
        <div class="left">
          <div class="left_btn1" @click="bookUrlBtn">
            <img src="@/assets/images/work/book_icon1.png" alt="">
            <span>链接转台本</span>
          </div>
          <div class="left_btn2" @click="bookVideoBtn">
            <img src="@/assets/images/work/book_icon2.png" alt="">
            <span>视频转台本</span>
          </div>
        </div>
        <div class="right" v-loading='loading'>
          <div class="no_html" v-if="list.length==0">
            <img src="@/assets/images/no_html/no_img_b.png" alt="">
            <div class="txt">这里什么都没有哦~</div>
          </div>
          <div class="list" v-for="(item,index) in list" :key="index" @click="detailsBtn(item.id,item.is_album)">
            <div class="img">
              <el-image style="width: 100%; height: 100%" :src="item.cover_url" fit="cover"></el-image>
              <div class="icon" v-if="item.is_album==1">
                <img src="@/assets/images/work/book_icon3.png" alt="">
              </div>
              <div class="icon2" v-if="item.is_sound==2">
                <img src="@/assets/images/work/book_icon4.png" alt="">
              </div>
            </div>
            <div class="list_info">
              <div class="info_name one-txt-cut">{{item.name}}</div>
              <div class="info_tip one-txt-cut">作者：{{item.author || '--'}}</div>
              <div class="info_btn">
                <span>{{item.diamond}}</span>
                <img class="dia" src="@/assets/images/icon/diamond_icon.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_box" v-if="total" style="padding-top: 20px;">
      	<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 链接转台本 -->
    <book-link ref="linkBook"></book-link>
    <!-- 视频转台本 -->
    <book-video ref="videoBook"></book-video>
	</div>
</template>

<script>
	import PagingPage from '@/components/paging_page/paging_page.vue'//分页
	import BookLink from '@/components/book_page/book_link.vue'//链接转台本
	import BookVideo from '@/components/book_page/book_video.vue'//视频转台本
	export default {
		components: {PagingPage,BookLink,BookVideo},
		props: {},
		data() {
			return{
        navIndex:0,
        navList:[],
        nextIndex:'',
        nextList:[],
        catId:"",
        //
        editValue:'',
        editOptions:[
          {value:1,label:'可编辑'},
          {value:2,label:'不可编辑'},
        ],
        tiValue:'',
        tiOptions:[],
        inputTxt:'',
        //
        list:[],
        //
        page: 1,
        limit: 10,
        total: 100,
        loading: false,
			}
		},
		computed: {},
		watch: {},
		created() {
      this.getParams()
    },
		mounted() {},
		methods: {
      // 一级分类
      getParams(){
        var that = this
        that.loading=true
        that.$workbenApi.marketScriptParams().then(res => {
          if (res.code == 1000) {
            that.navList=res.result.cat_arr
            that.tiOptions=res.result.is_sound
            this.getList()
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 获取下级分类
      nextParams(val){
        var that = this
        var params = {
          parent_id:val
        }
        that.$workbenApi.bookCatList(params).then(res => {
          if (res.code == 1000) {
            that.nextList=res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 切换
      navBtn(index){
        this.navIndex=index
        if(this.navList[index].id){
          this.catId=this.navList[index].id
          this.nextParams(this.navList[index].id)
        }else{
          this.nextList=[]
          this.nextIndex=''
          this.catId=''
        }
        this.page=1
        this.getList()
      },
      // 切换
      nextBtn(index){
        this.nextIndex=index
        this.catId=this.nextList[index].id
        this.page=1
        this.getList()
      },
      // 列表
      getList(){
        var that = this
        that.loading=true
        var parasm = {
          name:that.inputTxt,
          queryType:that.navList[that.navIndex].queryType,
          cat_id:that.catId,
          is_sound:that.tiValue,
          page:that.page,
          limit:that.limit
        }
        that.$workbenApi.marketScript(parasm).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.list=res.result.data
            that.total=res.result.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      bookListBtn(){
        this.page=1
        this.getList()
      },
      // 链接转台本
      bookUrlBtn(){
        this.$refs.linkBook.openDialog()
      },
      // 视频转台本
      bookVideoBtn(){
        this.$refs.videoBook.openDialog()
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
      // 详情
      detailsBtn(val,type){
        if(type==1){
          // 专辑
          var path = '/workben/album_details/'+val
          this.$router.push(path)
        }else{
          // 不是专辑
          var path = '/workben/drama_details/'+val
          this.$router.push(path)
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.tiben_market{
    width: 100%;
    .market_nav{
      width: 100%;
      padding: 10px 20px;
      background: #FFFFFF;
      border-radius: 10px;

      .nav_top{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .nav_left{
        display: flex;
        align-items: center;
        .text{
          cursor: pointer;
          padding: 0 20px;
          margin-right: 20px;
          height: 28px;
          line-height: 28px;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .text:hover{
          color: #fff;
          background: #2E4BF2;
        }
        .text_active{
          color: #fff;
          background: #2E4BF2;
        }
      }
      .nav_right{
        display: flex;
        align-items: center;
        .right_select{
          width: 110px;
          margin-left: 15px;
        }
        .t_input{
          width: 180px;
          margin-left: 15px;
        }
      }
      .next_list{
        margin-top: 10px;
        width: 100%;
        display: flex;
        .next_nav{
          cursor: pointer;
          margin-right: 10px;
          padding: 0 10px;
          height: 28px;
          line-height: 26px;
          border-radius: 4px;
          background: rgba(67,74,249,.1);
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #434af9;
        }
        .next_active{
          border: 1px solid #434af9;
        }
      }
    }
    .book_info{
      width: 100%;
      margin-top: 20px;
      padding: 10px 30px 20px 30px;
      background: #FFFFFF;
      border-radius: 10px;
      .book_info_main{
        display: flex;
      }
      .left{
        min-width: 166px;
        .left_btn1{
          cursor: pointer;
          margin-top: 20px;
          width: 100%;
          height: 87px;
          border: 1px dashed #2E4BF2;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img{
            display: block;
            width: 40px;
            height: 40px;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
          }
        }
        .left_btn2{
          cursor: pointer;
          margin-top: 20px;
          width: 100%;
          height: 87px;
          border: 1px dashed #E2E2E2;
          background: #fff;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img{
            display: block;
            width: 40px;
            height: 40px;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
          }
        }
      }
      /*  */
      .right{
        width: calc(100% - 166px);
        display: flex;
        flex-wrap: wrap;
        .list{
          margin-left: 20px;
          margin-right: 6px;
          margin-top: 20px;
          cursor: pointer;
          width: 207px;
          height: 325px;
          background: #fff;
          border-radius: 6px;
          border: 1px solid #F0F0F0;
          overflow: hidden;
          .img{
            position: relative;
            width: 100%;
            height: 245px;
            border-radius: 6px 6px 0 0;
            overflow: hidden;
            .icon{
              position: absolute;
              top: 0;
              left: 10px;
              width: 22px;
              height: 40px;
              img{
                display: block;
                width: 100%;
                height: 100%;
              }
            }
            .icon2{
              position: absolute;
              top: 0;
              right: 0;
              width: 24px;
              height: 24px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 0 0 0 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                display: block;
                width: 14px;
                height: 14px;
              }
            }
          }
          .list_info{
            width: 100%;
            padding: 0 10px;
            .info_name{
              padding-top: 10px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .info_tip{
              padding-top: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .info_btn{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .icon{
                display: block;
                width: 16px;
                height: 16px;
                margin-left: 10px;
              }
              span{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #2E4BF2;
              }
              .dia{
                display: block;
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
          }
        }
        .list:hover{
          box-shadow: 0px 2px 18px 0px rgba(157,189,255,0.5);
          border: 1px solid #2E4BF2;
        }
      }
    }
	}
</style>
